<template>
<div>
  
  <div >
    <!-- {{this.$store.state.app.user.user_id}} -->
    <!-- {{workspace in this.$store.state.auth.workspaces}} -->
    <div class="row">
      <div class="col-12 mb-1">
        <b>My Tasks</b>
      </div>
      <div class="col-12 mb-1">
        <div class="card h-100 mb-1">
          <div class="card-body">
            <table class="table border mb-1">
              <thead>
                <tr>
                  <th scope="col">Organization</th>
                  <th scope="col">Pending</th>
                  <th scope="col">In Progress</th>
                  <th scope="col">In Review</th>
                  <th scope="col">Approved</th>
                  <th scope="col">Rejected</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                v-for="(workspace, index) in this.$store.state.auth.workspaces"
                :key="workspace"
              >
                <!-- <td>{{ workspace.name }}</td>
                  <td>{{ taskssummary.myTasks[1] }}</td>
                  <td>{{ taskssummary.myTasks[2] }}</td>
                  <td>{{ taskssummary.myTasks[3] }}</td>
                  <td>{{ taskssummary.myTasks[4] }}</td>
                  <td>{{ taskssummary.myTasks[5] }}</td> -->

                <workspacecard :workspace="workspace" v-if="index < 10" />
              </tbody>
            </table>
            <!-- <workspacecard :workspace="workspace"/> -->
            <div class="text-center">
              <button class="btn btn-primary btn-sm" @click="gotoMytasks()" v-if="this.$store.state.auth.workspaces.length>10">See more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-1">
        <b>Tasks Assigned</b>
      </div>
      <div class="col-12 mb-1">
        <div class="card h-100 mb-1">
          <div class="card-body">
            <table class="table border mb-1">
              <thead>
                <tr>
                  <th scope="col">Organization</th>
                  <th scope="col">Pending</th>
                  <th scope="col">In Progress</th>
                  <th scope="col">In Review</th>
                  <th scope="col">Approved</th>
                  <th scope="col">Rejected</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                v-for="(workspace, index) in this.$store.state.auth.workspaces"
                :key="workspace"
              >
                <!-- <td>{{ workspace.name }}</td>
                  <td>{{ taskssummary.myTasks[1] }}</td>
                  <td>{{ taskssummary.myTasks[2] }}</td>
                  <td>{{ taskssummary.myTasks[3] }}</td>
                  <td>{{ taskssummary.myTasks[4] }}</td>
                  <td>{{ taskssummary.myTasks[5] }}</td> -->

                <workspacecardassign :workspace="workspace" v-if="index < 10" />
              </tbody>
            </table>
            <div class="text-center">
              <button class="btn btn-primary btn-sm" @click="gotoAssigntasks()" v-if="this.$store.state.auth.workspaces.length>10">See more</button>
            </div>
            <!-- <workspacecardassign :workspace="workspace"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>

  </div>
  </div>
</template>

<script>
import moment from "moment";
import { BTable, BAvatar, BBadge } from "bootstrap-vue";
import workspacecard from "./WorkspaceCard.vue";
import workspacecardassign from "./WorkspaceCardAssign.vue";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    moment,
    workspacecard,
    workspacecardassign,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/coming-soon.svg"),
      tasks: [],
      gaps: [],
    };
  },
  computed: {
    greeting() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var greet;

      if (hrs < 12) greet = "Good Morning";
      else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
      else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
      return greet;
    },
    // userid(){
    //  var uid = this.$store.state.app.user.user_id
    //  return uid

    // }
  },
  created() {},
  mounted() {},
  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
  },
  methods: {
    gotoMytasks(){
      this.$router.push("/mytasks");
    },
     gotoAssigntasks(){
      this.$router.push("/assignedtasks");
    },
    handleRowClick(id) {
      this.$router.push({
        name: "control-gap-asssessment",
        params: { id: id },
      });
    },
    getTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/tasks?page=1&status=1&limit=5`,
      };
      this.$http(options)
        .then((resp) => {
          this.tasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getGaps(uid) {
      console.log(this.$store.state.app.user.user_id + "inside function");
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/gap-assessments`,
        params: {
          limit: 5,
          assessed_by: this.$store.state.app.user.user_id,
        },
      };
      this.$http(options)
        .then((resp) => {
          this.gaps = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    gotoTask() {
      this.$router.push("/tasks");
    },
    gotogaps() {
      this.$router.push("/gapassessment");
    },
  },
};
</script>

<style lang="scss" scoped>
.misc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
th{
  background: #472183 !important;
  color: #fff;
}
</style>
